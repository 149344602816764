import { makeStyles } from '@mui/styles'
import * as React from 'react'
import { cloneElement } from 'react'
import {
    CreateButton,
    sanitizeListRestProps,
    TopToolbar,
    useListContext,
    Button,
    SortButton,
} from 'react-admin'
import PostQuickCreateButtonPayers from './PostQuickCreateButtonPayers'
import ResetFiltersButton from './ResetFiltersButton'

const toolbarStyles = makeStyles({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
})

const CustomCreateButton = (props) => {
    return !props.to ? (
        <CreateButton
            variant="outlined"
            color="success"
            label={'Dodaj'}
            {...props}
        />
    ) : (
        <CreateButton
            variant="outlined"
            color="success"
            label={'Dodaj'}
            {...props}
        />
    )
}

const CustomActionBar = (props) => {
    const {
        filtersDataFromRecords = null,
        filtersDataChoices = null,
        isAddVisible,
        isPopupAddVisible = false,
        isFiltersVisible = true,
        isNewIndividualOrder = false,
        sortBtnFields = null,
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    return (
        <TopToolbar
            //className={[className, toolbarStyles().root]}
            {...sanitizeListRestProps(rest)}
        >
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <ResetFiltersButton />
            {isAddVisible && (
                <CustomCreateButton {...props} basePath={basePath} />
            )}
            {isPopupAddVisible && <PostQuickCreateButtonPayers />}
            {sortBtnFields && <SortButton fields={sortBtnFields}/>}
        </TopToolbar>
    )
}

export default CustomActionBar
