import * as React from 'react'
import { List, TextField, TextInput, DateField, ReferenceField } from 'react-admin'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'

import CustomDataGrid from '../CustomElements/CustomDataGrid'
import EmptyList from '../EmptyList'
import { CustomPagination } from '../CustomElements/CustomPagination'

export default (props) => {
    return (
        <List
            {...props}
            empty={<EmptyList title="zamówień" add={false} />}
            actions={null}
            filters={[<TextInput variant="outlined" resettable alwaysOn label="ID zamówienia" source="orderNumber" />]}
            bulkActionButtons={false}
            title={`Śledzenie realizacji zamówień`}
            pagination={<CustomPagination />}
        >
            <CustomDataGrid>
                <TextField label="ID zamówienia" source="orderNumber" />
                <TextField label="Status" source="status" />
                <ReferenceField label="Punkt odbioru" reference="pickup_points" source="pickupPoint" />
                <DateField label="Data utworzenia" source="createdAt" showTime />
                <DateField label="Data aktualizacji" source="updatedAt" showTime />
            </CustomDataGrid>
        </List>
    )
}
