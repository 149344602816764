import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Slider from '@mui/material/Slider'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Grid, Typography } from '@mui/material'
import QuantityCounter from './QuantityCounter'

export default function InputSlider({
    price,
    pointsLimit,
    quantity,
    onQuantityChange,
    pointsUsed,
    onPointsUsedChange,
    pointsUserLimit,
    discountedPrice,
    onDiscountedPriceChange,
    maxQuantity,
}) {
    const pointValue = pointsLimit > 0 ? price / pointsLimit : 0
    const totalPoints = pointsLimit * quantity

    const handleSliderChange = (event, newValue) => {
        onPointsUsedChange(newValue)
        onDiscountedPriceChange(Math.max(price * (quantity ?? 1) - newValue * pointValue, 0).toFixed(2))
    }

    React.useEffect(() => {
        handleSliderChange(null, pointsUsed > totalPoints ? totalPoints : pointsUsed)
    }, [quantity, totalPoints, pointsUsed])

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                <QuantityCounter quantity={quantity} setQuantity={onQuantityChange} min={1} max={maxQuantity} />
            </Box>

            <Box sx={{ width: 400 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography>Punkty</Typography>
                        <Typography>Złotówki</Typography>
                    </Box>
                    <Box sx={{ margin: '5px 10px' }}>
                        <Slider
                            aria-label="Points slider"
                            min={0}
                            max={totalPoints > pointsUserLimit ? pointsUserLimit : totalPoints}
                            value={pointsUsed}
                            onChange={handleSliderChange}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography>{pointsUsed}</Typography>
                        <Typography>{discountedPrice}</Typography>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}
