import * as React from 'react'
import {
    Edit,
    ReferenceInput,
    required,
    SimpleForm,
    FormDataConsumer,
    AutocompleteInput,
    useGetOne,
    FunctionField,
    useGetList,
    usePermissions,
    WithRecord,
    BooleanInput
} from 'react-admin'

import { PostBottomToolbarEdit } from '../CustomElements/PostBottomToolbars'
import { CustomTitle } from '../CustomElements/CustomTitle'

const requiredField = required('Pole wymagane')

const transform = (data) => ({
    ...data,
    partnerName: data.partnerNameOracle,
    synchronize: true,
})

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    const {
        data: PartnersData,
        loading: PartnersDataLoading,
        error: PartnersError,
    } = useGetList('partners', {
        pagination: { page: 1, perPage: 50 },
        sort: { field: 'id', order: 'ASC' },
    })

    function forSiec(formData) {
        const needtoFind = formData

        const options = PartnersData?.filter((opt) => opt?.payerNetworks.includes(needtoFind))
        return options?.map((option) => ({
            id: option?.id,
            name: option?.name,
        }))
    }

    return (
        <Edit {...props} actions={null} title={<CustomTitle resourceName="Płatnik" fields={['name']} />} transform={transform}>
            <SimpleForm toolbar={<PostBottomToolbarEdit saveRedirectPath="/payers" isDeleteAvailable={false} />}>
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <WithRecord
                            render={(record) =>
                                !record.partnerName && (
                                    <>
                                        <FormDataConsumer>
                                            {({ formData }) =>
                                                formData.payerNetwork && (
                                                    <FunctionField
                                                        label="Partner"
                                                        source={forSiec(formData.payerNetwork)}
                                                        record={forSiec(formData.payerNetwork)}
                                                        render={(record) => {
                                                            return `${record?.[0]?.name}`
                                                        }}
                                                        allowEmpty={true}
                                                    />
                                                )
                                            }
                                        </FormDataConsumer>

                                        <ReferenceInput source="payerNetwork" reference="payer_networks" validate={requiredField}>
                                            <AutocompleteInput
                                                optionText={(record) => `(ID:${record?.id.split('/').pop()}) ${record?.name}`}
                                                label="Siec"
                                                variant="outlined"
                                                filterToQuery={(searchText) => ({ name: searchText })}
                                            />
                                        </ReferenceInput>
                                    </>
                                )
                            }
                        />
                        <BooleanInput source='isIndividualOrderAvailable' label='Czy może składać zamówienia indywidualne'/>
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    )
}
