import decodeJwt from 'jwt-decode'
import md5 from 'md5'

function createHeader() {
    const token = localStorage.getItem('token')
    const headers = new Headers()

    headers.append('Authorization', 'Bearer ' + token)
    headers.append('Content-Type', 'application/json')

    return headers
}

async function createOrder(order) {
    const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/profit_orders`, {
        method: 'POST',
        headers: createHeader(),
        body: JSON.stringify(order),
    })
    return fetch(request).then(async (response) => {
        if (response.status < 200 || response.status >= 300) {
            const error = await response.json();
            throw error?.["hydra:description"]??"Wystąpił błąd"
        }
        return response.json()
    })
}

//name, personPesel, pesonName, refundation, status, pickupPoint
async function addPoints(points) {
    const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/departments/add_points`, {
        method: 'POST',
        headers: createHeader(),
        body: JSON.stringify(points),
    })
    return fetch(request).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText)
        }
        return response.json()
    })
}
async function transferPoints(points) {
    const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/departments/transfer_points`, {
        method: 'POST',
        headers: createHeader(),
        body: JSON.stringify(points),
    })

    return fetch(request).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText)
        }
        return response.json()
    })
}
export default {
    addPoints,
    createOrder,
    transferPoints,
}
