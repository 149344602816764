import * as React from 'react'
import { Edit, required, SimpleForm, usePermissions, ReferenceArrayInput, AutocompleteArrayInput, BooleanInput, SelectInput } from 'react-admin'

import { PostBottomToolbarAdd } from '../CustomElements/PostBottomToolbars'
import { Divider } from '@mui/material'
import { CustomTitle } from '../CustomElements/CustomTitle'

const requiredField = required('Pole wymagane')
const isSameEmail = (value, allValues) => {
    if (allValues.email != value) {
        return 'Adresy e-mail się różnią'
    }

    return null
}
const validateSameEmail = [required('Pole wymagane'), isSameEmail]

const transform = (data) => {
    delete data.email_confirm
    return {
        ...data,
    }
}

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    return (
        <Edit {...props} actions={null} title={<CustomTitle resourceName="pracownik oddziału" fields={['name', 'surname']} />} transform={transform}>
            <SimpleForm toolbar={<PostBottomToolbarAdd saveRedirectPath="/department_users" />}>
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <SelectInput
                            source="type"
                            variant="outlined"
                            validate={requiredField}
                            choices={[
                                { id: 21, name: 'opiekun' },
                                { id: 25, name: 'admin DPS' },
                            ]}
                            fullWidth
                            label="Typ"
                        />
                        <ReferenceArrayInput source="departments" reference="departments">
                            <AutocompleteArrayInput
                                optionText="name"
                                resettable
                                filterToQuery={(searchText) => ({
                                    name: searchText,
                                })}
                                label="Oddziały"
                                fullWidth
                                variant="outlined"
                                validate={requiredField}
                            />
                        </ReferenceArrayInput>

                        <BooleanInput source="status" label="Status" />
                        <BooleanInput source="rewardAccess" label="Odbiór nagród" />
                        <BooleanInput source="manageRewardPoints" label="Zarządzanie pkt." />
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    )
}
