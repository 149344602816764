import * as React from 'react'
import {
    email,
    ReferenceManyField,
    required,
    Show,
    SimpleShowLayout,
    TextField,
    usePermissions,
    BooleanField,
    useDataProvider,
    useGetList,
    useShowController,
    RecordContextProvider,
    ReferenceField,
    FunctionField,
    useGetOne,
} from 'react-admin'
import Card from '@mui/material/Card'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import TextFieldMui from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Skeleton from '@mui/material/Skeleton'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    plPL,
} from '@mui/x-data-grid'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs'
import AsyncAutocomplete from '../CustomElements/AsyncAutocomplete'

import authProvider from '../../config/authProvider'

dayjs.extend(isSameOrAfter)

const toJsDate = (date, delimiter = '.') => {
    const regex = new RegExp(`(\\d{2})${delimiter}(\\d{2})${delimiter}(\\d{4})`, 'g')
    return new Date(date.replace(regex, '$3-$2-$1'))
}

const statuses = ['Zapłacony', 'Oczekuje na płatność', 'Weryfikacja']

const PickupPointInfo = ({ record }) => {
    if (record) {
        return (
            <RecordContextProvider value={record}>
                <SimpleShowLayout>
                    <Typography variant="h5">Dane rozliczeniowe</Typography>
                    <TextField source="name" label="Nazwa" />
                    <FunctionField label="Adres" render={(record) => `${record.street}, ${record.postCode} ${record.city}`} />
                    <TextField source="nip" label="NIP" />
                    {/* <TextField source="klor" label="Numer Kamsoft" /> */}
                </SimpleShowLayout>
            </RecordContextProvider>
        )
    }
}

const RefundListStatic = ({ ppId, colDef }) => {
    const [dateFrom, setDateFrom] = React.useState()
    const [dateTo, setDateTo] = React.useState()
    const [range, setRange] = React.useState()
    const [status, setStatus] = React.useState()
    const [number, setNumber] = React.useState()
    const [refundData, setRefundData] = React.useState()
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        setLoading(true)
        authProvider
            .getRefundInfo(ppId, '', '', true)
            .then((r) => setRefundData(r))
            .finally(() => setLoading(false))
    }, [ppId])

    const applyFilters = () => {
        return refundData?.filter(
            (el) =>
                (status ? el?.status == status : true) &&
                (range ? el?.okres == dayjs(range).format('MM/YYYY') : true) &&
                (dateFrom ? toJsDate(el?.dataNoty).getTime() >= new Date(dateFrom).getTime() : true) &&
                (dateTo ? toJsDate(el?.dataNoty).getTime() <= new Date(dateTo).getTime() : true) &&
                (number ? el?.numerNoty.toLowerCase().includes(number.toLowerCase()) : true)
        )
    }

    return refundData && !loading ? (
        <div
            style={{
                height: applyFilters()?.length > 0 ? '100%' : 200,
                width: '100%',
            }}
            className="my-2"
        >
            <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between" style={{ flex: 0.7 }}>
                    <DatePicker
                        value={dateFrom}
                        onChange={(value) => setDateFrom(value)}
                        label="Data od"
                        slotProps={{
                            field: { clearable: true },
                            textField: {
                                variant: 'outlined',
                            },
                        }}
                    />
                    <DatePicker
                        value={dateTo}
                        onChange={(value) => setDateTo(value)}
                        label="Data do"
                        slotProps={{
                            field: { clearable: true },
                            textField: {
                                variant: 'outlined',
                            },
                        }}
                    />
                    <DatePicker
                        value={range}
                        onChange={(value) => setRange(value)}
                        label="Okres"
                        slotProps={{
                            field: { clearable: true },
                            textField: {
                                variant: 'outlined',
                            },
                        }}
                        views={['month', 'year']}
                    />
                    <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select labelId="status-label" value={status} onChange={(e) => setStatus(e.target.value)} label="Status">
                            <MenuItem value={null}>&nbsp;</MenuItem>
                            {statuses.map((s) => (
                                <MenuItem value={s}>{s}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <TextFieldMui value={number} onChange={(e) => setNumber(e.target.value)} label="Numer noty" variant="outlined" />
            </div>
            <DataGrid
                rows={applyFilters()}
                columns={colDef}
                getRowId={(row) => row?.numerNoty}
                disableColumnMenu
                slots={{
                    toolbar: () => (
                        <GridToolbarContainer>
                            <GridToolbarExport />
                            <GridToolbarDensitySelector />
                            <GridToolbarColumnsButton />
                        </GridToolbarContainer>
                    ),
                }}
                localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'dataNoty', sort: 'desc' }],
                    },
                }}
            />
        </div>
    ) : (
        <Card variant="outlined" className="p-2 my-2">
            <Skeleton />
            <Skeleton />
            <Typography className="text-center" variant="h6">
                Ładowanie...
            </Typography>
            <Skeleton />
            <Skeleton />
        </Card>
    )
}

const RefundListDynamic = ({ ppId, colDef }) => {
    const [dateFrom, setDateFrom] = React.useState()
    const [dateTo, setDateTo] = React.useState()
    const [refundData, setRefundData] = React.useState()
    const [loading, setLoading] = React.useState(false)

    const getData = () => {
        setLoading(true)
        authProvider
            .getRefundInfo(ppId, dateFrom ? dayjs(dateFrom).format('YYYY-MM-DD') : '', dateTo ? dayjs(dateTo).format('YYYY-MM-DD') : '')
            .then((r) => setRefundData(r))
            .finally(() => setLoading(false))
    }

    React.useEffect(() => {
        setRefundData(null)
    }, [ppId])

    return (
        <div
            style={{
                height: refundData?.length > 0 || !refundData ? '100%' : 200,
                width: '100%',
            }}
            className="my-2"
        >
            <div className="d-flex justify-content-between align-items-center w-50">
                <DatePicker
                    value={dateFrom}
                    onChange={(value) => setDateFrom(value)}
                    label="Data od"
                    slotProps={{
                        field: { clearable: true },
                        textField: {
                            variant: 'outlined',
                        },
                    }}
                />
                <DatePicker
                    value={dateTo}
                    onChange={(value) => setDateTo(value)}
                    label="Data do"
                    slotProps={{
                        field: { clearable: true },
                        textField: {
                            variant: 'outlined',
                        },
                    }}
                />
                <Button onClick={getData} variant="outlined" size="small">
                    Wczytaj dane
                </Button>
            </div>
            {refundData && !loading ? (
                <DataGrid
                    rows={refundData}
                    columns={colDef}
                    getRowId={(row) => row?.numerNoty}
                    disableColumnMenu
                    slots={{
                        toolbar: () => (
                            <GridToolbarContainer>
                                <GridToolbarExport />
                                <GridToolbarDensitySelector />
                                <GridToolbarColumnsButton />
                            </GridToolbarContainer>
                        ),
                    }}
                    localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'dataNoty', sort: 'desc' }],
                        },
                    }}
                />
            ) : loading ? (
                <Card variant="outlined" className="p-2 my-2">
                    <Skeleton />
                    <Skeleton />
                    <Typography className="text-center" variant="h6">
                        Ładowanie...
                    </Typography>
                    <Typography className="d-block text-center" variant="caption">
                        (Odpytujemy system zewnętrzny, serdecznie prosimy o cierpliwość)
                    </Typography>
                    <Skeleton />
                    <Skeleton />
                </Card>
            ) : null}
        </div>
    )
}

export default (props) => {
    const dataProvider = useDataProvider()
    const { isLoading, permissions } = usePermissions()
    const [selectedOption, setSelectedOption] = React.useState()
    const [selectedOptionObj, setSelectedOptionObj] = React.useState()

    const columns: GridColDef[] = [
        {
            field: 'dataNoty',
            headerName: 'Data',
            type: 'date',
            flex: 0.5,
            valueGetter: (params) => (params.value ? toJsDate(params.value) : params.value),
        },
        {
            field: 'okres',
            headerName: 'Okres',
            flex: 0.5,
            valueFormatter: (params) => (params?.value ? dayjs(params.value, 'MM/YYYY', 'pl-PL').format('MMMM YYYY') : ''),
        },
        { field: 'numerNoty', headerName: 'Numer noty', flex: 1 },
        {
            field: 'nazwaApteki',
            headerName: 'Nazwa punktu odbioru',
            sortable: false,
            flex: 1,
            minWidth: 150,
            valueGetter: (params) => `${params?.row?.nazwaApteki || ''} ${params?.row?.adresApteki || ''}`,
        },
        { field: 'status', headerName: 'Status', flex: 0.5 },
        {
            field: 'kwotaNoty',
            headerName: 'Wartość',
            type: 'number',
            flex: 0.5,
            valueFormatter: (params) => (params?.value ? `${params.value} zł` : ''),
        },
        { field: 'dataZaplaty', headerName: 'Data przelewu', flex: 0.5 },
        {
            field: 'actions',
            type: 'actions',
            flex: 0.1,
            getActions: (params) => [
                params.row?.plikNoty == 'Y' ? (
                    <GridActionsCellItem
                        icon={<FileDownloadIcon />}
                        label="Pobierz plik pdf"
                        onClick={() => getRefundPdf(selectedOptionObj?._id, params.id)}
                    />
                ) : (
                    <></>
                ),
            ],
        },
    ]

    const getRefundPdf = (pickupPointId, invoiceNumber, integration = 'oracle_tzmo') => {
        window.open(
            `${process.env.REACT_APP_API_ENTRYPOINT}/ords/pickup_point_refund_pdf/${pickupPointId}?pNoteNum=${invoiceNumber}&integration=${integration}`
        )
    }

    const fetchInstitutions = (_name = null) => {
        return dataProvider.getList('pickup_points', {
            pagination: { page: 1, perPage: 15 },
            sort: { field: 'name', order: 'ASC' },
            filter: { internalName: _name ?? '' },
        })
    }

    return (
        <Card className="p-2">
            <Box>
                <AsyncAutocomplete
                    label="Wyszukaj punkt odbioru"
                    optionLabel="internalName"
                    value={selectedOption}
                    setValue={setSelectedOption}
                    callback={fetchInstitutions}
                    sx={{ width: '50%' }}
                    setFullValue={setSelectedOptionObj}
                    disableClearable={false}
                    firstAsDefault={true}
                />
                {selectedOptionObj && (
                    <Box sx={{ paddingBottom: 8 }}>
                        <PickupPointInfo record={selectedOptionObj} />
                        <RefundListStatic ppId={selectedOptionObj?._id} colDef={columns} />
                        <Alert sx={{ mt: 8, mb: 1 }} severity="warning">
                            UWAGA! Jeśli potrzebujesz danych archiwalnych, prosimy o wybranie odpowiedniego zakresu dat i kliknięcie przycisku
                            "WCZYTAJ DANE"
                        </Alert>
                        <RefundListDynamic ppId={selectedOptionObj?._id} colDef={columns} />
                    </Box>
                )}
            </Box>
        </Card>
    )
}
