import * as React from 'react'
import {
    AutocompleteInput,
    Filter,
    FunctionField,
    List,
    ReferenceInput,
    TextField,
    SelectInput,
    usePermissions,
    useRecordContext,
    BooleanField,
    TextInput,
    useListContext,
    useDataProvider,
    useGetOne,
    useRefresh,
} from 'react-admin'
import CustomBooleanField from '../CustomFields/CustomBooleanField'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'
import CustomActionBar from '../CustomElements/CustomActionBar'
//import CustomDataGrid from "./OldCustomDataGrid";
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import CustomTypeField from '../CustomFields/CustomTypeField'
import EmptyList from '../EmptyList'
import { CustomPagination } from '../CustomElements/CustomPagination'
import { useLocation } from 'react-router'

import PermissionHandler from '../../config/PermissionHandler'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import WorkersCreatedPopup from './WorkersCreatedPopup'
import { Chip } from '@mui/material'

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    const { filterValues, setFilters } = useListContext()
    const { data: userData } = useGetOne('pk_users', { id: `pk_users/me` })
    const dataprovider = useDataProvider()
    const [showPopup, setShowPopup] = React.useState(false)
    const [popupData, setPopupData] = React.useState(null)
    const types = [
        { id: 10, name: 'pracownik' },
        { id: 20, name: 'administrator' },
    ]
    const [actualCity, setActualCity] = React.useState('')
    const location = useLocation()
    const refresh = useRefresh()
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        const id = location?.search?.split('id=')?.[1]
        if (id && userData?.firstName == 'Temporary' && userData?.lastName == 'User') {
            dataprovider.getOne('workers', { id: `workers/${id}` }).then(({ data }) => {
                setPopupData(data)
                setShowPopup(true)
            })
        } else if (id) {
            setShowPopup(true)
        }
    }, [userData])

    React.useEffect(() => {
        const url = decodeURI(window.location.hash)
        const searchParams = new URLSearchParams(url)
        const filterObj = JSON.parse(searchParams.get('filter'))
        if (filterObj && filterObj._pickupPoints) setActualCity(filterObj._pickupPoints.city)
        else setActualCity('')
    })

    return (
        <>
            <WorkersCreatedPopup isOpen={showPopup} setIsOpen={setShowPopup} data={popupData} />
            <List
                {...props}
                pagination={<CustomPagination />}
                empty={<EmptyList title="pracowników" add={PermissionHandler(permissions, 'Workers', 'Create')} />}
                actions={<CustomActionBar isAddVisible={PermissionHandler(permissions, 'Workers', 'Create')} />}
                filters={[
                    <ReferenceInput source="pickupPoints" reference="pickup_points" link={false} alwaysOn filter={{ city: actualCity }}>
                        <AutocompleteInput
                            label="Punkt odbioru"
                            optionText="internalName"
                            variant="outlined"
                            filterToQuery={(searchText) => ({
                                internalName: searchText,
                            })}
                            sx={{ minWidth: 300 }}
                            resettable
                            validate={null}
                        />
                    </ReferenceInput>,
                    <TextInput alwaysOn label="Miejscowość" variant="outlined" source="_pickupPoints.city" resettable />,
                    PermissionHandler(permissions, 'Other', 'CanLogin') ? (
                        <TextInput alwaysOn label="Partner" source="payer.payerNetwork.partner.name" variant="outlined" resettable />
                    ) : (
                        <></>
                    ),
                    <TextInput alwaysOn label="Nazwisko" source="user.surname" variant="outlined" resettable />,
                    <TextInput alwaysOn label="Adres e-mail" source="user.email" variant="outlined" resettable />,
                    <TextInput alwaysOn label="NIP" source="payer.nip" variant="outlined" resettable />,
                    <TextInput alwaysOn label="Numer PKODB" source="_pickupPoints.pkodbNumber" variant="outlined" resettable />,
                    // <SelectInput label="Typ" source="type" choices={types} alwaysOn variant="outlined" resettable />,
                ]}
                bulkActionButtons={false}
                title={`Pracownicy`}
            >
                <CustomDataGrid rowStyle={postRowStyle}>
                    <FunctionField label="ID pracownika" source="id" render={(record) => `${record.id.split('/')[2]}`} />
                    <TextField source="name" label="Imię" />
                    <TextField source="surname" label="Nazwisko" />
                    <CustomTypeField source="type" label="Typ" />
                    <TextField source="email" label="E-mail" />
                    <TextField source="payerNetworkName" label="Płatnik sieciowy" />
                    {PermissionHandler(permissions, 'Other', 'CanLogin') && <TextField source="partner" sortable={false} label="Partner" />}
                    {PermissionHandler(permissions, 'Other', 'CanLogin') && <TextField source="pkodbNumbers" sortable={false} label="Numer PKODB" />}

                    <BooleanField source="status" label="Status" valueLabelTrue="aktywny" valueLabelFalse="nieaktywny" />

                    {PermissionHandler(permissions, 'Workers', 'IsWorkerActivated') && (
                        <FunctionField
                            label="Aktywancja konta pracownika"
                            render={(record) => {
                                return (
                                    <Tooltip title={record.isActivated ? null : 'Kliknij żeby aktywować'}>
                                        <Chip
                                            label={record.isActivated ? 'aktywny' : 'nieaktywny'}
                                            color={record.isActivated ? 'success' : 'warning'}
                                            variant="outlined"
                                            clickable={!record.isActivated}
                                            disabled={loading}
                                            onClick={() => {
                                                setLoading(true)
                                                dataprovider
                                                    .update('pk_users', {
                                                        id: '/pk_users/' + record.userId,
                                                        data: { activated: true },
                                                        previousData: record,
                                                    })
                                                    .finally(() => {
                                                        refresh()
                                                        setLoading(false)
                                                    })
                                            }}
                                        />
                                    </Tooltip>
                                )
                            }}
                        />
                    )}
                    {PermissionHandler(permissions, 'Other', 'CanLogin') && (
                        <FunctionField
                            label="Link logowania"
                            render={(record) => {
                                const [title, setTitle] = React.useState('Kliknij żeby skopiować')
                                return (
                                    <Tooltip title={title}>
                                        <Link
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                navigator.clipboard.writeText(record?.loginUrl)
                                                setTitle('Skopiowano')
                                                setTimeout(() => {
                                                    setTitle('Kliknij żeby skopiować')
                                                }, 2000)
                                            }}
                                            fontSize={10}
                                        >
                                            {record?.loginUrl}
                                        </Link>
                                    </Tooltip>
                                )
                            }}
                        />
                    )}
                    <CustomWorkerActionsButtons permissions={permissions} />
                </CustomDataGrid>
            </List>
        </>
    )
}

const CustomWorkerActionsButtons = ({ permissions }) => {
    const record = useRecordContext()
    const { data } = useListContext()
    const { data: meData, isLoading: meLoadning } = useGetOne('pk_users', {
        id: `pk_users/me`,
    })
    const managers = data.filter((worker) => {
        return worker.type == 20
    })

    return (
        <>
            {!record.isDeleted && PermissionHandler(permissions, 'Workers', 'Update') && (
                <CustomActionsButtons label="Akcje" isEditAvailable={PermissionHandler(permissions, 'Workers', 'Update')} />
            )}
            {!record.isDeleted &&
                !meLoadning &&
                (record.type != 20 || !(managers.length <= 1) || permissions.includes('ROLE_PAYER') || permissions.includes('ROLE_PAYER_NETWORK')) &&
                record.email != meData.email &&
                PermissionHandler(permissions, 'Workers', 'Update') && (
                    <CustomActionsButtons label="Akcje" isDeleteAvailable={PermissionHandler(permissions, 'Workers', 'Delete')} />
                )}
        </>
    )
}

const postRowStyle = (record, index) => {
    if (record.isDeleted) {
        return {
            backgroundColor: '#898989',
        }
    }
}
