import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { useRedirect, useListContext } from 'react-admin'
import { Typography } from '@mui/material'
import QuantityCounter from './QuantityCounter'

const CustomCardGrid = () => {
    const { data } = useListContext()

    const Inputs = ({ product }) => {
        const [quantity, setQuantity] = React.useState(1)
        const redirect = useRedirect()

        const formOrder = (id) => {
            redirect(`/profit_products/%2Fprofit_products%2F${id}/show?quantity=${quantity}`)
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                <QuantityCounter quantity={quantity} setQuantity={setQuantity} min={1} max={product?.unlimited ? undefined : product?.quantity} />
                <Button sx={{marginTop:"10px"}} variant="contained" color="primary" onClick={() => formOrder(product.originId)}>
                    Zamawiam
                </Button>
            </Box>
        )
    }

    return (
        <Box>
            {data?.map((_product) => (
                <Card
                    style={{
                        minWidth: 200,
                        minHeight: 200,
                        margin: '0.5em',
                        display: 'inline-block',
                        verticalAlign: 'top',
                    }}
                >
                    <CardContent>
                        <Typography gutterBottom variant="h5">
                            {_product.name}
                        </Typography>

                        <img src={_product.images[0]?.contentUrl} alt={_product.name} style={{ width: '100%', height: 'auto' }} />
                        <Typography variant="body1">Wartość PLN: {_product.price} zł</Typography>
                        <Typography variant="body1">Wartość PKT: {_product.points} pkt</Typography>
                    </CardContent>
                    <CardActions>
                        <Inputs product={_product} />
                    </CardActions>
                </Card>
            ))}
        </Box>
    )
}

export default CustomCardGrid
