import React, { useState } from 'react'
import { useDataProvider, useGetOne } from 'react-admin'
import { Card, CardContent, Typography, Box, TextField, Button } from '@mui/material'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'notistack'
import rewardsProvider from '../../../config/rewardsProvider'
import AsyncAutocomplete from '../../CustomElements/AsyncAutocomplete'

export default (props) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const dataProvider = useDataProvider()
    const [selectedDepartment, setSelectedDepartment] = useState('')
    const [selectedDepartmentObj, setSelectedDepartmentObj] = useState(null)
    const [selectedDepartment2, setSelectedDepartment2] = useState('')
    const [selectedDepartmentObj2, setSelectedDepartmentObj2] = useState(null)
    const [pointsToTransfer, setPointsToTransfer] = useState('')
    const [loading, setLoading] = useState(false)

    const { data: pointsData, isLoading, error } = useGetOne('', { id: 'pk_users/get_points_data' })
    const {
        data: meData,
        loading: meLoading,
        error: meError,
    } = useGetOne('pk_users', {
        id: `pk_users/me`,
    })

    if (!meData?.rewardPointsManage && !meLoading) {
        enqueueSnackbar(`Brak uprawnień`, {
            variant: 'error',
            autoHideDuration: 10000,
            preventDuplicate: true
        })
        navigate(-1)
    }

    if ((!pointsData?.departments || pointsData?.departments.length === 0) && !isLoading) {
        return <Typography>Brak oddziałów</Typography>
    }

    const confirmPoints = (e) => {
        e.preventDefault()

        const transferData = {
            department: selectedDepartmentObj?.id,
            department2: selectedDepartmentObj2?.id,
            points: pointsToTransfer,
        }
        setLoading(true)
        rewardsProvider
            .transferPoints(transferData)
            .then((r) => {
                if (r.success) {
                    enqueueSnackbar(`Punkty zostały pomyślnie przeniesione`, {
                        variant: 'success',
                        autoHideDuration: 10000,
                    })
                } else {
                    enqueueSnackbar(`Wystąpił błąd: ${r.message}`, {
                        variant: 'error',
                        autoHideDuration: 10000,
                    })
                }
            })
            .catch((e) =>
                enqueueSnackbar(`Wystąpił błąd: ${e}`, {
                    variant: 'error',
                    autoHideDuration: 10000,
                })
            )
            .finally(() => setLoading(false))
    }

    const fetchDepartments = (_name = null) => {
        return dataProvider.getList('departments', {
            pagination: { page: 1, perPage: 15 },
            sort: { field: 'name', order: 'ASC' },
            filter: { name: _name ?? '', 'pickupPoint.payer.rewardType': 1 },
        })
    }

    return (
        <Card>
            <CardContent>
                <form onSubmit={confirmPoints}>
                    <Typography gutterBottom variant="h4">
                        Przepisywanie punktów między oddziałami
                    </Typography>
                    <Typography gutterBottom variant="h6">
                        Liczba punktów dostępna na Twoich oddziałach
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '500px' }}>
                        {pointsData?.departments.map((dept) => (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Typography key={dept.id}>{dept.name}</Typography>
                                <Typography>{dept.points} pkt</Typography>
                            </Box>
                        ))}

                        <Typography gutterBottom variant="h6">
                            Przepisywanie punktów{' '}
                        </Typography>

                        <AsyncAutocomplete
                            label="Wybierz oddział, z którego chcesz przenieść punkty"
                            optionLabel="name"
                            value={selectedDepartment}
                            setValue={setSelectedDepartment}
                            callback={fetchDepartments}
                            sx={{ width: 'unset' }}
                            setFullValue={setSelectedDepartmentObj}
                            disableClearable={false}
                        />
                        <AsyncAutocomplete
                            label="Wybierz oddział, na który mają trafić punkty"
                            optionLabel="name"
                            value={selectedDepartment2}
                            setValue={setSelectedDepartment2}
                            callback={fetchDepartments}
                            sx={{ width: 'unset' }}
                            setFullValue={setSelectedDepartmentObj2}
                            disableClearable={false}
                        />

                        <TextField
                            variant="outlined"
                            label="Ile punktów ma zostać przeniesiono"
                            value={pointsToTransfer}
                            onChange={(e) => setPointsToTransfer(e.target.value)}
                            type="number"
                        />

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            <Button variant="outlined" color="warning" onClick={() => navigate(-1)}>
                                ANULUJ
                            </Button>
                            <Button
                                disabled={loading || !selectedDepartmentObj || !selectedDepartmentObj2 || pointsToTransfer <= 0}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                WYKONAJ
                            </Button>
                        </Box>
                    </Box>
                </form>
            </CardContent>
        </Card>
    )
}
