import * as React from 'react'
import {
    List,
    TextField,
    FunctionField,
    Button,
    TextInput,
    DateField,
    ReferenceField,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    usePermissions,
    useUpdate,
    useRedirect,
    useGetOne,
} from 'react-admin'

import CustomDataGrid from '../CustomElements/CustomDataGrid'
import CustomActionBar from '../CustomElements/CustomActionBar'
import EmptyList from '../EmptyList'
import PermissionHandler from '../../config/PermissionHandler'
import { CustomPagination } from '../CustomElements/CustomPagination'
import { useSnackbar } from 'notistack'
import MuiTextField from '@mui/material/TextField'
import { Link } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'

export default (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const { isLoading, permissions } = usePermissions()
    const redirect = useRedirect()

    React.useEffect(() => {
        if (localStorage.getItem('indOrds') != 'true') {
            enqueueSnackbar('Nie posiadasz dostępu', {
                variant: 'error',
                autoHideDuration: 5000,
            })
            redirect('/')
        }
    }, [])

    return (
        <>
            <Button
                component={Link}
                variant="contained"
                color="secondary"
                href={window.location.protocol + '/files/prowizja_dla_aptek_2.pdf'}
                target="_blank"
            >
                Aktualne wartości prowizji
            </Button>
            <List
                {...props}
                empty={<EmptyList title="zamówień indywidualnych" add={true} />}
                actions={<CustomActionBar isAddVisible={PermissionHandler(permissions, 'IndividualOrders', 'Create')} label="Nowe zamówienie" />}
                filters={[
                    <DateInput variant="outlined" resettable alwaysOn label="Data" source="createdAt" />,
                    <TextInput variant="outlined" resettable alwaysOn label="Id zamówienia" source="id" sx={{ maxWidth: 175 }} />,
                    <TextInput variant="outlined" resettable alwaysOn label="Numer zamówienia (Oracle)" source="oracleNumber" />,
                    <TextInput variant="outlined" resettable alwaysOn label="Imię i nazwisko pacjenta" source="patientName" />,
                    <ReferenceInput source="pickupPoint" reference="pickup_points" link={false} alwaysOn>
                        <AutocompleteInput
                            label="Miejsce przyjęcia zamówienia"
                            optionText="internalName"
                            variant="outlined"
                            filterToQuery={(searchText) => ({
                                internalName: searchText,
                            })}
                            sx={{ minWidth: 300 }}
                            resettable
                            validate={null}
                        />
                    </ReferenceInput>,
                    <TextInput variant="outlined" resettable alwaysOn label="Numer telefonu" source="patientPhone" />,
                ]}
                bulkActionButtons={false}
                title={`Zamówienia indywidualne`}
                pagination={<CustomPagination />}
                sort={{ field: 'id', order: 'DESC' }}
            >
                <CustomDataGrid>
                    <FunctionField source="id" label="ID zamówienia" render={(record) => `${record.id.split('/').pop()}`} />
                    {PermissionHandler(permissions, 'IndividualOrders', 'Update') ? (
                        <FunctionField label="Numer Oracle" render={(record) => <OracleNumberField record={record} />} />
                    ) : (
                        <TextField source="oracleNumber" label="Numer Oracle" />
                    )}
                    <DateField source="createdAt" label="Data zamówienia" />
                    <ReferenceField link={false} source="pickupPoint" reference="pickup_points" label="Miejsce przyjęcia zamówienia">
                        <TextField source="internalName" />
                    </ReferenceField>
                    <ReferenceField link={false} source="pickupPoint" reference="pickup_points" label="Numer lokalizacji">
                        <TextField source="pkodbNumber" />
                    </ReferenceField>
                    <TextField source="patientName" label="Imię i nazwisko pacjenta" />
                    <TextField source="patientPhone" label="Numer telefonu pacjenta" />
                    <TextField source="patientAddress" label="Adres pacjenta" />
                    {PermissionHandler(permissions, 'IndividualOrders', 'Update') && (
                        <FunctionField label="Akcje" render={(record) => <CancelBtn record={record} />} />
                    )}
                </CustomDataGrid>
            </List>
        </>
    )
}

const OracleNumberField = ({ record }) => {
    const [value, setValue] = React.useState(null)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [update] = useUpdate(undefined, undefined, {
        onError: (error) =>
            enqueueSnackbar(error, {
                variant: 'error',
                autoHideDuration: 10000,
            }),
        onSuccess: (data) =>
            enqueueSnackbar('Pomyślnie zmieniono numer oracle', {
                variant: 'success',
                autoHideDuration: 10000,
            }),
    })

    const onSave = () => {
        update('individual_orders', {
            id: record.id,
            data: { oracleNumber: value },
            previousData: record,
        })
    }

    return record ? (
        <>
            <MuiTextField
                type="number"
                defaultValue={record?.oracleNumber}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                inputProps={{ min: 0 }}
                variant="outlined"
                resettable
            />
            <br />
            <Button label="Zapisz" onClick={onSave} disabled={record?.oracleNumber == value || value == null} variant="outlined" color="warning" />
        </>
    ) : null
}

const CancelBtn = ({ record }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const confirm = useConfirm()
    const [update] = useUpdate(undefined, undefined, {
        onError: (error) =>
            enqueueSnackbar(error, {
                variant: 'error',
                autoHideDuration: 10000,
            }),
        onSuccess: (data) =>
            enqueueSnackbar('Pomyślnie anulowano zamówienie', {
                variant: 'success',
                autoHideDuration: 10000,
            }),
    })

    const onSave = () => {
        confirm({
            title: 'Czy na pewno chcesz anulować zamówienie?',
            description: `Wybrane zamówienie zostanie oznaczone jako anulowane i przeniesione do archiwum.
            Ważne - anulowanie w Panelu Klienta nie skutkuje anulacją zamówienia w zewnętrznych systemach informatycznych.`,
            confirmationText: 'Anuluj',
            confirmationButtonProps: {
                variant: 'contained',
                color: 'warning',
            },
            cancellationText: 'Wróć',
            cancellationButtonProps: {
                variant: 'outlined',
                color: 'primary',
            },
            allowClose: true,
        }).then(() => {
            update('individual_orders', {
                id: record.id,
                data: { cancelled: true, cancelledAt: new Date().toISOString() },
                previousData: record,
            })
        }).catch(()=>{})
    }

    return record ? <Button label="Anuluj" onClick={onSave} variant="outlined" color="error" /> : null
}
