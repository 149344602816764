import * as React from 'react'
import {
    List,
    TextField,
    DateField,
    TextInput,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    BulkUpdateButton,
    ExportButton,
    FunctionField,
    ReferenceField,
    BulkExportButton,
    usePermissions,
} from 'react-admin'
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'
import CustomActionBar from '../CustomElements/CustomActionBar'
import EmptyList from '../EmptyList'
import { CustomPagination } from '../CustomElements/CustomPagination'
import PermissionHandler from '../../config/PermissionHandler'
import { Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material'

const statusOptions = [
    { id: 1, name: 'oczekujący' },
    { id: 2, name: 'w realizacji' },
    { id: 3, name: 'zrealizowany' },
]

export default (props) => {
    const { isLoading, permissions } = usePermissions()

    const BulkAction = (props) => {
        const [status, setStatus] = React.useState('')
        const handleChange = (event) => {
            setStatus(event.target.value)
        }

        return (
            <Box sx={{ display: 'flex' }}>
                {PermissionHandler(permissions, 'ProfitOrders', 'Update') && (
                    <>
                        <FormControl sx={{ minWidth: 200, marginRight: 10 }} size="small">
                            <InputLabel>Zmiana statusu</InputLabel>
                            <Select variant="outlined" color="primary" onChange={handleChange}>
                                {statusOptions.map((o) => (
                                    <MenuItem value={o.id}>{o.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <BulkUpdateButton label="Zaktualizuj" data={{ status: status }} />
                    </>
                )}
                <BulkExportButton label="Eksportuj" />
            </Box>
        )
    }
    const getStatusName = (status) => {
        const statusOption = statusOptions.find((option) => option.id === status)
        return statusOption ? statusOption.name : 'Nieznany'
    }

    return (
        <List
            title="Lista zamówień nagród"
            filters={[
                <DateInput label="Data po" source="createdAt[after]" alwaysOn variant="outlined" resettable />,
                <DateInput label="Data przed" source="createdAt[before]" alwaysOn variant="outlined" resettable />,
                <ReferenceInput source="pickupPoint" reference="pickup_points" link={false} className="autocompleteinput" alwaysOn allowEmpty>
                    <AutocompleteInput
                        label="Punkt odbioru"
                        optionText="internalName"
                        variant="outlined"
                        resettable
                        filterToQuery={(searchText) => ({
                            internalName: searchText,
                        })}
                    />
                </ReferenceInput>,
                <SelectInput alwaysOn variant="outlined" resettable label="Status" source="status" choices={statusOptions} />,
                <TextInput alwaysOn variant="outlined" resettable label="Nazwa nagrody" source="product.name" />,
                <TextInput alwaysOn variant="outlined" resettable label="E-mail" source="email" />,
                // <TextInput alwaysOn variant="outlined" resettable label="Przedstawiciel" source="nameSurname" />,
            ]}
            pagination={<CustomPagination />}
            actions={<CustomActionBar />}
            empty={<EmptyList />}
        >
            <CustomDataGrid
                bulkActionButtons={<BulkAction />}
            >
                <DateField source="createdAt" label="Data" />
                <ReferenceField source="pickupPoint" reference="pickup_points" label="Punkt Odbioru" link={false}>
                    <FunctionField source="name" render={(record) => `${record.name} ${record.postCode} ${record.city} `} />
                </ReferenceField>
                <TextField source="email" label="E-mail" />
                {/* <Checkbox checked={selectedRows.includes((record) => record.id)} onChange={() => handleSelectRow()} /> */}
                <TextField source="name" label="Nazwa nagrody" />
                {/* <TextField source="nameSurname" label="Przedstawiciel" /> */}
                <FunctionField label="Status" source="status" render={(record) => getStatusName(record.status)} />
                <TextField source="amount" label="Wartość PLN" />
                <TextField source="points" label="Wartość PKT" />

                <CustomActionsButtons label="Akcje" isEditAvailable={false} isDeleteAvailable={false} isDescriptionAvailable={true} />
            </CustomDataGrid>
        </List>
    )
}
