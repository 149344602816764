import * as React from 'react'
import { DateField, ReferenceManyField, Show, SimpleShowLayout, TextField, FunctionField, ReferenceField, useShowController } from 'react-admin'
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import { CustomTitle } from '../CustomElements/CustomTitle'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material'
import _ from 'lodash'

const statusOptions = [
    { id: 1, name: 'oczekujący' },
    { id: 2, name: 'w realizacji' },
    { id: 3, name: 'zrealizowany' },
]

export default (props) => {
    const getStatusName = (status) => {
        const statusOption = statusOptions.find((option) => option.id === status)
        return statusOption ? statusOption.name : 'Nieznany'
    }

    return (
        <Show {...props} title={<CustomTitle resourceName="Zamowienie" fields={['name']} />}>
            <SimpleShowLayout>
                <FunctionField label="Numer zamówienia" source="id" render={(record) => `${record.id.split('/').pop()}`} />
                <DateField variant="outlined" source="createdAt" label="Data zamówienia" showTime />
                <ReferenceField source="pickupPoint" reference="pickup_points" label="Punkt Odbioru" link={false}>
                    <TextField source="internalName" />
                </ReferenceField>
                <ReferenceField source="user" reference="pk_users" label="Imię i nazwisko zamawiającego" link={false}>
                    <FunctionField source="name" render={(record) => `${record.name} ${record.surname}`} />
                </ReferenceField>
                <TextField variant="outlined" source="email" label="Email zamawiającego" />
                <TextField variant="outlined" source="phoneNumber" label="Numer telefonu zamawiającego" />
                <FunctionField label="Status zamówienia" source="status" render={(record) => getStatusName(record.status)} />
                <ReferenceField source="pickupPoint" reference="pickup_points" label="Przedstawiciel handlowy" link={false}>
                    <TextField source="salesRepresentive" emptyText="-" />
                </ReferenceField>
                {/* <TextField variant="outlined" source="method" label="Metoda platności" /> */}
                <ReferenceField label="Pozycje zamówienia" source="product" reference="profit_products">
                    <FunctionField
                        render={(record) => {
                            const { record: mainRecord } = useShowController()
                            return record && mainRecord ? (
                                <TableContainer component={Paper}>
                                    <Table sx={{ width: '100%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nazwa</TableCell>
                                                <TableCell align="right">Ilość sztuk</TableCell>
                                                <TableCell align="right">Cena jednostkowa PLN</TableCell>
                                                <TableCell align="right">Cena jednostkowa pkt</TableCell>
                                                <TableCell align="right">Wartość PLN</TableCell>
                                                <TableCell align="right">Wartość pkt</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    {record.name}{' '}
                                                    [{_.isObject(JSON.parse(mainRecord?.attributeValue)) &&
                                                        Object.entries(JSON.parse(mainRecord?.attributeValue))
                                                            .map(([key, value]) => `${key}: ${value}`)
                                                            .join(', ')}]
                                                </TableCell>
                                                <TableCell align="right">{mainRecord.quantity}</TableCell>
                                                <TableCell align="right">{record.price}</TableCell>
                                                <TableCell align="right">{record.points}</TableCell>
                                                <TableCell align="right">{mainRecord.amount}</TableCell>
                                                <TableCell align="right">{mainRecord.points}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : null
                        }}
                    />
                </ReferenceField>
            </SimpleShowLayout>
        </Show>
    )
}
