import React, { useState } from 'react'
import { useDataProvider } from 'react-admin'
import { Card, CardContent, Typography, Box, TextField, Button } from '@mui/material'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'notistack'
import rewardsProvider from '../../../config/rewardsProvider'
import AsyncAutocomplete from '../../CustomElements/AsyncAutocomplete'

export default (props) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const dataProvider = useDataProvider()
    const [selectedInstitution, setSelectedInstitution] = useState('')
    const [selectedInstitutionObj, setSelectedInstitutionObj] = useState(null)
    const [selectedDepartment, setSelectedDepartment] = useState('')
    const [selectedDepartmentObj, setSelectedDepartmentObj] = useState(null)
    const [pointsToAdd, setPointsToAdd] = useState(0)
    const [loading, setLoading] = useState(false)

    const confirmPoints = (e) => {
        e.preventDefault()
        const addPointsData = {
            institution: selectedInstitutionObj?.id,
            department: selectedDepartmentObj?.id,
            points: pointsToAdd,
        }
        setLoading(true)
        rewardsProvider
            .addPoints(addPointsData)
            .then((r) => {
                if (r.success) {
                    enqueueSnackbar(`Punkty zostały pomyślnie dodane`, {
                        variant: 'success',
                        autoHideDuration: 10000,
                    })
                } else {
                    enqueueSnackbar(`Wystąpił błąd: ${r.message}`, {
                        variant: 'error',
                        autoHideDuration: 10000,
                    })
                }
            })
            .catch((e) =>
                enqueueSnackbar(`Wystąpił błąd: ${e}`, {
                    variant: 'error',
                    autoHideDuration: 10000,
                })
            )
            .finally(()=>setLoading(false))
    }

    const fetchInstitutions = (_name = null) => {
        return dataProvider.getList('payers', {
            pagination: { page: 1, perPage: 15 },
            sort: { field: 'name', order: 'ASC' },
            filter: { name: _name ?? '', payerType: 2, rewardType: 2 },
        })
    }

    const fetchDepartments = (_name = null) => {
        return dataProvider.getList('departments', {
            pagination: { page: 1, perPage: 15 },
            sort: { field: 'name', order: 'ASC' },
            filter: { name: _name ?? '', 'pickupPoint.payer.rewardType': 1 },
        })
    }

    return (
        <Card>
            <CardContent>
                <form onSubmit={confirmPoints}>
                    <Typography gutterBottom variant="h4">
                        Ręczne dodawanie punktów
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '500px' }}>
                        <AsyncAutocomplete
                            label="Wybierz instytucje, do której chcesz dodać punkty"
                            optionLabel="name"
                            value={selectedInstitution}
                            setValue={setSelectedInstitution}
                            callback={fetchInstitutions}
                            sx={{ width: 'unset' }}
                            setFullValue={setSelectedInstitutionObj}
                            disableClearable={false}
                        />

                        <AsyncAutocomplete
                            label="Wybierz oddział, do którego chcesz dodać punkty"
                            optionLabel="name"
                            value={selectedDepartment}
                            setValue={setSelectedDepartment}
                            callback={fetchDepartments}
                            sx={{ width: 'unset' }}
                            setFullValue={setSelectedDepartmentObj}
                            disableClearable={false}
                        />

                        <TextField
                            variant="outlined"
                            label="Ile punktów ma zostać dodane"
                            value={pointsToAdd}
                            onChange={(e) => setPointsToAdd(e.target.value)}
                            type='number'
                        />

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            <Button variant="outlined" color="warning" onClick={() => navigate(-1)}>
                                ANULUJ
                            </Button>
                            <Button disabled={loading || (!selectedDepartmentObj && !selectedInstitutionObj) || pointsToAdd <= 0} variant="contained" color="primary" type="submit">
                                WYKONAJ
                            </Button>
                        </Box>
                    </Box>
                </form>
            </CardContent>
        </Card>
    )
}
