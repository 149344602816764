import * as React from 'react'
import {
    Create,
    required,
    SimpleForm,
    TextInput,
    usePermissions,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput,
} from 'react-admin'

import { PostBottomToolbarAdd } from '../CustomElements/PostBottomToolbars'
import { Divider } from '@mui/material'

const requiredField = required('Pole wymagane')
const isSameEmail = (value, allValues) => {
    if (allValues.email != value) {
        return 'Adresy e-mail się różnią'
    }

    return null
}
const validateSameEmail = [required('Pole wymagane'), isSameEmail]

const transform = (data) => {
    delete data.email_confirm
    return {
        ...data,
    }
}

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    return (
        <Create {...props} actions={null} title={`Dodaj pracownika oddziału`} transform={transform}>
            <SimpleForm toolbar={<PostBottomToolbarAdd saveRedirectPath="/department_users" />}>
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <TextInput variant="outlined" label={'Imię'} source="name" validate={requiredField} fullWidth />
                        <TextInput variant="outlined" label={'Nazwisko'} source="surname" validate={requiredField} fullWidth />
                        <TextInput variant="outlined" source="phone" label="Numer telefonu" fullWidth validate={requiredField} />
                        <TextInput
                            variant="outlined"
                            source="email"
                            label="Adres e-mail"
                            fullWidth
                            validate={requiredField}
                            onPaste={(e) => {
                                e.preventDefault()
                                return false
                            }}
                            onDrop={(e) => {
                                e.preventDefault()
                                return false
                            }}
                        />
                        <TextInput
                            variant="outlined"
                            source="email_confirm"
                            validate={validateSameEmail}
                            onPaste={(e) => {
                                e.preventDefault()
                                return false
                            }}
                            onDrop={(e) => {
                                e.preventDefault()
                                return false
                            }}
                            autoComplete="new-password"
                            label="Powtórz adres e-mail"
                            fullWidth
                        />
                        <Divider sx={{ margin: '10px 0px 20px 0' }} />
                        <SelectInput
                            source="type"
                            variant="outlined"
                            validate={requiredField}
                            choices={[
                                { id: 21, name: 'opiekun' },
                                { id: 25, name: 'admin DPS' },
                            ]}
                            fullWidth
                            label="Typ"
                        />
                        <ReferenceArrayInput source="departments" reference="departments">
                            <AutocompleteArrayInput
                                optionText="name"
                                resettable
                                filterToQuery={(searchText) => ({
                                    name: searchText,
                                })}
                                label="Oddziały"
                                fullWidth
                                variant="outlined"
                                validate={requiredField}
                            />
                        </ReferenceArrayInput>

                        <BooleanInput source="status" label="Status" />
                        <BooleanInput source="rewardAccess" label="Odbiór nagród" />
                        <BooleanInput source="manageRewardPoints" label="Zarządzanie pkt." />
                    </div>
                </div>
            </SimpleForm>
        </Create>
    )
}
