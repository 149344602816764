import React from 'react'
import CustomDataGrid from '../../CustomElements/CustomDataGrid'
import CustomActionBar from '../../CustomElements/CustomActionBar'
import { CustomActionsButtons } from '../../CustomFields/CustomActionButtonsField'
import { CustomPagination } from '../../CustomElements/CustomPagination'
import EmptyList from '../../EmptyList'
import { List, TextField, TextInput, NumberInput, FunctionField, CreateButton, SelectInput, BooleanField } from 'react-admin'

export default (props) => (
    <List
        {...props}
        resource="profit_products"
        title={`Lista nagród`}
        filters={[
            <TextInput alwaysOn variant="outlined" resettable label="Tytuł" source="name" />,
            <SelectInput
                label="Typ nagrody"
                source="type"
                variant="outlined"
                resettable
                fullWidth
                choices={[
                    { id: 0, name: ' rzeczowa' },
                    { id: 1, name: 'edukacyjna' },
                ]}
                alwaysOn
            />,

            <TextInput alwaysOn variant="outlined" resettable label="ID" source="id" />,
            <NumberInput alwaysOn variant="outlined" resettable label="Cena w PKT" source="points" />,
            <NumberInput alwaysOn variant="outlined" resettable label="Cena w PLN" source="price" />,
        ]}
        pagination={<CustomPagination />}
        actions={(<CustomActionBar isAddVisible={true} />)}
        empty={<EmptyList add={true} title="nagród" />}
    >
        <CustomDataGrid>
            <FunctionField source="id" render={(record) => record.id.split('/')[2]} label="ID" />
            <TextField source="name" label="Tytuł" />
            <BooleanField source='active' label="Status" valueLabelTrue='dostępne' valueLabelFalse='nie dostępne' />
            <TextField source="points" label="Cena w pkt." />
            <TextField source="price" label="Cena w PLN" />
            <CustomActionsButtons label="Akcje" isEditAvailable={true} isDeleteAvailable={true} />
        </CustomDataGrid>
    </List>
)
