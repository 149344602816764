import * as React from 'react'
import useStateRef from 'react-usestateref'
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Typography,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Button,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormGroup,
    Checkbox,
    Select,
    MenuItem,
    InputAdornment,
    TextField,
    IconButton,
    Switch,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Divider,
    LinearProgress,
    Chip,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Title, useGetList, useStore, useDataProvider, useRedirect } from 'react-admin'
import { useSnackbar } from 'notistack'
import { useConfirm } from 'material-ui-confirm'
import DynamicFormIcon from '@mui/icons-material/DynamicForm'
import OrderTypeSelection from './partials/OrderTypeSelection'
import ProductSearch from './partials/ProductSearch'
import OrderLines from './partials/OrderLines'
import RefundScale from '../Patients/partials/RefundScale'
import NewTemplateModal from './partials/NewTemplateModal'
import dpsProvider from '../../config/dpsProvider'
import fastOrderProvider from '../../config/fastOrderProvider'
import ezwmProvider from '../../config/ezwmProvider'
import dayjs from 'dayjs'
import OrderSummary from './partials/OrderSummary'
import DepartmentsNewSimpleOrder from './partials/simple/DepartmentsNewSimpleOrder'

const refundRangeOptions = [
    { value: 1, label: '1 miesiąc' },
    { value: 2, label: '2 miesiące' },
    { value: 3, label: '3 miesiące' },
]

const getTitle = (orderProps) => {
    let result = ''
    switch (orderProps.orderType) {
        case 1:
            result += 'Zamówienie z dofinansowaniem NFZ, '
            break
        case 2:
            result += 'Zamówienie bez dofinansowania NFZ, '
            break
        case 3:
            result += 'Zamówienie bez dofinansowania NFZ na oddział, '
            break
    }

    switch (orderProps.invoice) {
        case 'patient':
            result += 'faktura na podopiecznego, '
            break
        case 'dps':
            result += 'faktura na DPS, '
            break
    }

    switch (orderProps.payment) {
        case 'cash':
            result += 'płatność gotówką'
            break
        case 'transfer':
            result += 'płatność przelewem'
            break
        case 'auto':
            result += 'płatność auto'
            break
    }

    return result
}

const checkoutStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}

const currencyFormatter = new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
})

const OrderLinesMemo = React.memo(OrderLines)

export default () => {
    const dataProvider = useDataProvider()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const confirm = useConfirm()
    const redirect = useRedirect()

    const [patients, setPatients] = React.useState([])
    const [dpsPreferences, setDpsPreferences] = useStore('order_dps_preferences', false)
    const [refundRange, setRefundRange] = React.useState(1)
    const [expandAll, setExpandAll] = React.useState(false)
    const [shouldSelectAll, setShouldSelectAll] = React.useState(true)
    const [expanded, setExpanded, expandedRef] = useStateRef({})
    const [modalOpen, setModalOpen] = React.useState(false)
    const [sending, setSending, sendingRef] = useStateRef(false)
    const [refundStatuses, setRefundStatuses] = React.useState(null)
    const [refundStatusesLoading, setRefundStatusesLoading, refundStatusesLoadingRef] = useStateRef(false)

    const [summaryPage, setSummaryPage] = React.useState(false)

    const [orderSimpleDetails, setOrderSimpleDetails] = useStore('order_simple_dps_details', {})
    const [dpsDetails, setDpsDetails] = useStore('order_dps_details', [])
    const [summaryAll, setSummaryAll] = useStore('order_dps_summary', {})
    const [, updateState] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])

    const getRefundPrice = (row, patient) => {
        const normalPriceUnit = getNormalPrice(row) / row?.pack_quantity
        const reduction = (row.limit_refund > normalPriceUnit ? normalPriceUnit : row.limit_refund) * (patient?.refundDocument?.refundReduction / 100)
        return reduction * row?.quantity * row?.pack_quantity
    }

    const getPatientPrice = (row, patient) => {
        const normalPriceUnit = getNormalPrice(row) / row?.pack_quantity
        const price = getNormalPrice(row) * row?.quantity
        return price - (row.limit_refund > normalPriceUnit ? normalPriceUnit : row.limit_refund) * row?.quantity * row?.pack_quantity
    }

    const getNormalPrice = (row) => {
        return parseFloat(row?.dpsPrice ?? row?.price) || 0
    }

    const updateSummaries = (orderDetails, setOrderDetails, dpsPreferences, patients, summaryAll, setSummaryAll) => {
        let _total = 0
        let _patient = 0
        let _dps = 0
        let _nfz = 0

        orderDetails?.map((p) => {
            let patient = patients.find((_p) => _p._id == p.patient_id)

            p.summaryTotal = Array.isArray(p?.products)
                ? p?.products?.reduce((accumulator, currentValue) => accumulator + getNormalPrice(currentValue) * currentValue.quantity, 0)
                : 0
            p.summaryPatient =
                dpsPreferences.orderType == 1 && Array.isArray(p?.products)
                    ? p?.products?.reduce((accumulator, currentValue) => accumulator + getPatientPrice(currentValue, patient), 0)
                    : 0
            p.summaryNfz =
                dpsPreferences.orderType == 1 && Array.isArray(p?.products)
                    ? p?.products?.reduce((accumulator, currentValue) => accumulator + getRefundPrice(currentValue, patient), 0)
                    : 0

            p.summaryDps = dpsPreferences.orderType == 1 ? p.summaryTotal - (p.summaryPatient + p.summaryNfz) : 0

            if (p.selected) {
                _total += p.summaryTotal
                _patient += p.summaryPatient
                _dps += p.summaryDps
                _nfz += p.summaryNfz
            }
        })

        if (orderDetails?.length > 0) {
            summaryAll.summaryTotal = _total
            summaryAll.summaryPatient = _patient
            summaryAll.summaryDps = _dps
            summaryAll.summaryNfz = _nfz

            setOrderDetails(orderDetails)
            setSummaryAll(summaryAll)
        }
    }

    React.useEffect(() => {
        setTimeout(() => forceUpdate(), 1)
    }, shouldSelectAll)

    React.useEffect(() => {
        updateSummaries(dpsDetails, setDpsDetails, dpsPreferences, patients, summaryAll, setSummaryAll)
        forceUpdate()
    }, [refundStatuses?.length, JSON.stringify(dpsDetails), JSON.stringify(dpsPreferences), patients.length])

    React.useEffect(() => {
        const deptFilter = dpsPreferences?.department
            ? Object.entries(dpsPreferences?.department)
                  .filter((key, val) => key[1] == true)
                  .map((x) => x[0].split('/')[2])
            : null
        if (!deptFilter?.length || dpsPreferences.orderType == 3) {
            setPatients([])
            return
        }

        dataProvider
            .getList('patients', {
                pagination: { page: 1, perPage: 999 },
                sort: { field: 'id', order: 'DESC' },
                filter: { department: deptFilter },
            })
            .then((result) => {
                setPatients(result.data)
                const defaultDpsDetails = []

                result?.data?.map((patient) => {
                    return fastOrderProvider.getPatientProducts(patient._id).then((products) => {
                        const existsPatient = dpsDetails?.findIndex((obj) => obj.patient_id == patient._id)
                        const haveProducts = dpsDetails?.[existsPatient]?.products?.length > 0
                        if (existsPatient == -1 && !haveProducts) {
                            defaultDpsDetails.push({ patient_id: patient._id, products: products })
                        }
                        setTimeout(() => setDpsDetails([...dpsDetails, ...defaultDpsDetails]), 0)
                    })
                })

                if (dpsPreferences.orderType == 1) {
                    setRefundStatusesLoading(true)
                    ezwmProvider
                        .getRefundDocsStatusesByPatients(result?.data?.map((patient) => patient._id))
                        .then((statuses) => setRefundStatuses(statuses))
                        .finally(() => setRefundStatusesLoading(false))
                }
                return result.data
            })
    }, [dpsPreferences])

    const selectAll = () => {
        setDpsDetails(
            dpsDetails.map((d) => ({
                ...d,
                selected:
                    d?.products?.length > 0 &&
                    shouldSelectAll &&
                    (dpsPreferences.orderType == 1 ? refundStatuses?.[d?.patient_id]?.[dayjs().format('YYYY-MM')] == 'B' : true),
            }))
        )
        setShouldSelectAll(!shouldSelectAll)
    }

    const PatientSummary = ({ patient, active = false, dpsDetails, _refundStatuses, dpsPreferences }) => {
        const patientStatuses = _refundStatuses?.[patient._id]

        const orderDetails = dpsDetails?.find((obj) => obj.patient_id == patient._id)

        const monthlyQuantity = dpsPreferences.orderType == 1 ? patient?.refundDocument?.['wyroby']?.['lb-szt-na-mies'] || 0 : 0
        const availableQuantity =
            dpsPreferences.orderType == 1 && Array.isArray(orderDetails?.products)
                ? monthlyQuantity * refundRange -
                  orderDetails?.products?.reduce(
                      (_accumulator, _currentValue) => _accumulator + _currentValue?.pack_quantity * _currentValue.quantity,
                      0
                  )
                : 0

        const subTotalSummary = Array.isArray(orderDetails?.products)
            ? orderDetails?.products?.reduce(
                  (_accumulator, _currentValue) => _accumulator + getNormalPrice(_currentValue) * _currentValue.quantity,
                  0
              )
            : 0

        const subPatientSummary =
            dpsPreferences.orderType == 1 && Array.isArray(orderDetails?.products)
                ? orderDetails?.products?.reduce((accumulator, currentValue) => accumulator + getPatientPrice(currentValue, patient), 0)
                : 0

        return !active ? (
            <Box sx={{ display: 'flex', flex: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1, minWidth: 400, marginRight: 5 }}>
                    <Typography>{`${patient.name} ${patient.secondName ? `${patient.secondName} ${patient.surname}` : patient.surname} (ID ${
                        patient._id
                    })`}</Typography>
                    {dpsPreferences.orderType == 1 && (
                        <Typography>{patient?.refundDocument?.['wyroby']?.['lb-szt-na-mies'] * refundRange || 0} szt</Typography>
                    )}
                </Box>

                <Box sx={{ display: 'flex', flex: 2, justifyContent: 'space-around' }}>
                    {orderDetails?.products && Array.isArray(orderDetails?.products) && (
                        <>
                            <Divider orientation="vertical" />
                            <Box>
                                {orderDetails?.products?.map((p) => (
                                    <Typography>{p.name}</Typography>
                                ))}
                            </Box>
                            <Box>
                                <Typography>wartość</Typography>
                                <Typography fontWeight="bold">{currencyFormatter.format(subTotalSummary ?? 0)}</Typography>
                            </Box>
                            {dpsPreferences?.orderType == 1 && (
                                <Box>
                                    <Typography>dopłata pacjenta</Typography>
                                    <Typography fontWeight="bold">{currencyFormatter.format(subPatientSummary ?? 0)}</Typography>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        ) : (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
                    <Typography>{`${patient.name} ${patient.secondName ? `${patient.secondName} ${patient.surname}` : patient.surname} (ID ${
                        patient._id
                    })`}</Typography>
                    <Box sx={{ display: 'flex' }}>
                        <Typography marginRight={5}>Oddział:</Typography>
                        <Typography fontWeight="bold">{patient._department.name}</Typography>
                    </Box>
                    {dpsPreferences?.orderType == 1 && (
                        <>
                            <Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography marginRight={5}>Miesięczna ilość:</Typography>
                                    <Typography fontWeight="bold">{monthlyQuantity}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography marginRight={5}>Dostępna ilość:</Typography>
                                    <Typography color={availableQuantity < 0 ? 'red' : 'unset'} fontWeight="bold">
                                        {availableQuantity}
                                    </Typography>
                                </Box>
                                {availableQuantity < 0 && (
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography fontWeight="bold" color={'red'} marginRight={5}>
                                            Ilość produktów przekroczyła limit miesięczny.
                                            <br />
                                            Prosimy zwiększyć okres lub zmniejszyć ilość produktów
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                            {!refundStatusesLoadingRef.current ? (
                                patientStatuses && (
                                    <Box>
                                        <RefundScale refundMonths={Object.entries(patientStatuses).map(([key, value]) => ({ [key]: value }))} />
                                    </Box>
                                )
                            ) : (
                                <Box width={200}>
                                    <LinearProgress />
                                </Box>
                            )}
                        </>
                    )}
                </Box>
                {!refundStatusesLoadingRef.current &&
                    patient?.refundDocument &&
                    dpsPreferences.orderType == 1 &&
                    refundStatuses?.[patient._id]?.[dayjs().format('YYYY-MM')] != 'B' && (
                        <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                            <Chip
                                variant="outlined"
                                color="error"
                                label="Nie można złożyć zamówienia z refundacją na tego podopiecznego. Zlecenie przypisane do danego podopiecznego jest niedostępne w tym miesiącu"
                            />
                        </Box>
                    )}
            </Box>
        )
    }

    const handleSelect = (patientId) => {
        const dpsDetailsIndex = getPatientIndex(patientId)
        if (dpsDetails[dpsDetailsIndex] && dpsDetails[dpsDetailsIndex]?.products?.length) {
            dpsDetails[dpsDetailsIndex].selected = !dpsDetails[dpsDetailsIndex].selected
            setDpsDetails(dpsDetails)
            setTimeout(() => forceUpdate(), 0)
        }
    }

    // const handleCash = (patientId) => {
    //     const dpsDetailsIndex = getPatientIndex(patientId)
    //     if (dpsDetails[dpsDetailsIndex]) {
    //         dpsDetails[dpsDetailsIndex].isCash = !dpsDetails[dpsDetailsIndex].isCash
    //         setDpsDetails(dpsDetails)
    //         setTimeout(() => forceUpdate(), 0)
    //     }
    // }

    const handleChange = (patientId) => (event) => {
        expandedRef.current[patientId] = !expandedRef.current[patientId]
        setExpanded(expandedRef.current)
        setTimeout(() => forceUpdate(), 0)
    }

    const getPatientIndex = (patientId) => dpsDetails?.findIndex((obj) => obj.patient_id == patientId)

    const canSubmitOrder = (onlySelected = false) => {
        return (
            dpsDetails?.filter(
                (patient) =>
                    (dpsPreferences.orderType == 1 ? refundStatuses?.[patient?.patient_id]?.[dayjs().format('YYYY-MM')] == 'B' : true) &&
                    (onlySelected ? patient?.selected && patient?.products?.length > 0 : patient?.products?.length > 0)
            )?.length > 0
        )
    }

    const goToSummary = () => {
        if (!canSubmitOrder(true)) {
            enqueueSnackbar('Brak zaznaczonych pacjentów lub brak produktów', {
                variant: 'error',
                autoHideDuration: 5000,
            })
            return
        }
        const orderLines = dpsDetails.filter(
            (oL) => oL?.selected && (dpsPreferences.orderType == 1 ? refundStatuses?.[oL?.patient_id]?.[dayjs().format('YYYY-MM')] == 'B' : true)
        )
        setDpsDetails(orderLines)
        setExpandAll(true)
        setSummaryPage(true)
    }

    const Summary = () => {
        return (
            <>
                <Box sx={checkoutStyles}>
                    <Typography>Wartość zamówienia</Typography>
                    <Typography>{currencyFormatter.format(summaryAll?.summaryTotal ?? 0)}</Typography>
                </Box>
                {dpsPreferences.orderType == 1 && (
                    <>
                        <Divider sx={{ margin: '10px 0' }} />
                        <Box sx={checkoutStyles}>
                            <Typography>Wartość dopłaty Pacjentów</Typography>
                            <Typography>{currencyFormatter.format(summaryAll?.summaryPatient ?? 0)}</Typography>
                        </Box>
                        <Divider sx={{ margin: '10px 0' }} />
                        <Box sx={checkoutStyles}>
                            <Typography>Wartość dopłaty NFZ</Typography>
                            <Typography>{currencyFormatter.format(summaryAll?.summaryNfz ?? 0)}</Typography>
                        </Box>
                        <Divider sx={{ margin: '10px 0' }} />
                        <Box sx={checkoutStyles}>
                            <Typography>Wartość dopłaty DPS</Typography>
                            <Typography>{currencyFormatter.format(summaryAll?.summaryDps ?? 0)}</Typography>
                        </Box>
                    </>
                )}
            </>
        )
    }

    return (
        <Box>
            <Card>
                <Title title="Zamówienie DPS" />
                <CardContent>
                    {summaryPage ? (
                        <OrderSummary
                            setSummaryPage={setSummaryPage}
                            dpsPreferences={dpsPreferences}
                            dpsDetails={dpsDetails}
                            refundRange={refundRange}
                            refundRangeOptions={refundRangeOptions}
                            getTitle={getTitle}
                            patients={patients}
                            getPatientIndex={getPatientIndex}
                            PatientSummary={PatientSummary}
                            refundStatuses={refundStatuses}
                            summaryAll={summaryAll}
                            forceUpdate={forceUpdate}
                            handleChange={handleChange}
                            Summary={Summary}
                            expandAll={expandAll}
                            setDpsPreferences={setDpsPreferences}
                            setDpsDetails={setDpsDetails}
                            setSummaryAll={setSummaryAll}
                            setShouldSelectAll={setShouldSelectAll}
                        />
                    ) : !dpsPreferences ? (
                        <OrderTypeSelection />
                    ) : (
                        <Box>
                            <NewTemplateModal isOpen={modalOpen} setIsOpen={setModalOpen} products={{ ...dpsPreferences, patients: dpsDetails }} />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="warning"
                                    onClick={() => {
                                        setDpsPreferences(false)
                                        setDpsDetails([])
                                        setSummaryAll({})
                                        setOrderSimpleDetails({})
                                        setShouldSelectAll(true)
                                    }}
                                >
                                    Wróć do ustawień
                                </Button>
                                <Typography variant="subtitle1" maxWidth={400}>
                                    {dpsPreferences && getTitle(dpsPreferences)}
                                </Typography>
                                {dpsPreferences.orderType == 1 && (
                                    <TextField
                                        select
                                        variant="outlined"
                                        sx={{ minWidth: 200 }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">Okres:</InputAdornment>,
                                        }}
                                        value={refundRange}
                                        onChange={(e) => setRefundRange(e.target.value)}
                                    >
                                        {refundRangeOptions.map((o) => (
                                            <MenuItem value={o.value}>{o.label}</MenuItem>
                                        ))}
                                    </TextField>
                                )}
                                {dpsPreferences.orderType != 3 && (
                                    <Box sx={{ display: 'flex' }}>
                                        <FormControlLabel
                                            labelPlacement="start"
                                            label="Rozwiń/zwiń wszystkie"
                                            control={<Switch onClick={() => setExpandAll(!expandAll)} />}
                                        />
                                        <FormControlLabel
                                            labelPlacement="start"
                                            label="Zaznacz/odznacz wszystkie"
                                            control={<Checkbox onClick={() => selectAll()} />}
                                        />
                                    </Box>
                                )}
                            </Box>
                            {refundStatusesLoadingRef.current && (
                                <Box sx={{ margin: '10px' }}>
                                    <Typography textAlign="center" variant="subtitle2">
                                        Status zleceń refundacyjnych jest aktualizowany, prosimy o cierpliwość
                                    </Typography>
                                    <LinearProgress color="warning" />
                                </Box>
                            )}
                            {dpsPreferences.orderType == 3 ? (
                                <DepartmentsNewSimpleOrder forceUpdate={forceUpdate} />
                            ) : (
                                <>
                                    {patients && (
                                        <Box sx={{ marginTop: 3 }}>
                                            {patients?.map((patient) => (
                                                <Box sx={{ display: 'flex' }}>
                                                    <Accordion
                                                        key={patient.id}
                                                        expanded={expandedRef.current[patient.id] || expandAll}
                                                        onChange={handleChange(patient.id)}
                                                        sx={{
                                                            flex: 1,
                                                            filter:
                                                                dpsPreferences.orderType == 1 &&
                                                                patient?.refundDocument &&
                                                                refundStatuses?.[patient._id]?.[dayjs().format('YYYY-MM')] != 'B'
                                                                    ? 'brightness(95%)'
                                                                    : 'unset',
                                                        }}
                                                        slotProps={{ transition: { unmountOnExit: true } }}
                                                    >
                                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                            <PatientSummary
                                                                patient={patient}
                                                                active={expandedRef.current[patient.id] || expandAll}
                                                                dpsDetails={dpsDetails}
                                                                _refundStatuses={refundStatuses}
                                                                dpsPreferences={dpsPreferences}
                                                            />
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Box>
                                                                <OrderLinesMemo
                                                                    patient={patient}
                                                                    forceUpdate={forceUpdate}
                                                                    refundRange={refundRange}
                                                                />
                                                            </Box>
                                                        </AccordionDetails>
                                                        <AccordionActions>
                                                            {patient?.refundDocument &&
                                                            dpsPreferences.orderType == 1 &&
                                                            refundStatuses?.[patient._id]?.[dayjs().format('YYYY-MM')] == 'B' ? (
                                                                <ProductSearch
                                                                    patient={patient}
                                                                    forceUpdate={forceUpdate}
                                                                    refundRange={refundRange}
                                                                />
                                                            ) : (
                                                                dpsPreferences.orderType != 1 && (
                                                                    <ProductSearch patient={patient} forceUpdate={forceUpdate} />
                                                                )
                                                            )}
                                                        </AccordionActions>
                                                    </Accordion>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '4px' }}>
                                                        <Checkbox
                                                            sx={{ alignSelf: 'center' }}
                                                            disabled={
                                                                !dpsDetails[getPatientIndex(patient._id)] ||
                                                                !dpsDetails[getPatientIndex(patient._id)]?.products?.length ||
                                                                (dpsPreferences.orderType == 1 &&
                                                                    patient?.refundDocument &&
                                                                    refundStatuses?.[patient._id]?.[dayjs().format('YYYY-MM')] != 'B')
                                                            }
                                                            checked={!!dpsDetails[getPatientIndex(patient._id)]?.selected}
                                                            onChange={() => handleSelect(patient._id)}
                                                        />
                                                        {/* {dpsDetails[getPatientIndex(patient._id)]?.selected && (
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={dpsDetails[getPatientIndex(patient._id)]?.isCash}
                                                                        onChange={(e) => handleCash(patient._id)}
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography fontSize="0.75rem" variant="body2">
                                                                        Czy płatność nastąpi gotówką
                                                                    </Typography>
                                                                }
                                                                labelPlacement="top"
                                                                sx={{ margin: 0 }}
                                                            />
                                                        )} */}
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                    )}
                                    <Box sx={{ display: 'flex', margin: '30px 0' }}>
                                        <Box sx={{ flex: 1 }} />
                                        <Box sx={{ flex: 1 }}>
                                            <Divider sx={{ margin: '10px 0' }} />
                                            <Summary />
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}>
                                                <Button
                                                    disabled={!canSubmitOrder(false) || sendingRef.current}
                                                    onClick={() => setModalOpen(true)}
                                                    sx={{ marginRight: '20px' }}
                                                    variant="contained"
                                                    color="warning"
                                                    startIcon={<DynamicFormIcon />}
                                                >
                                                    Zapisz jako szablon
                                                </Button>
                                                <Button
                                                    disabled={!canSubmitOrder(true) || sendingRef.current}
                                                    onClick={() => goToSummary()}
                                                    variant="contained"
                                                    color="success"
                                                >
                                                    Przejdź do realizacji zamówienia
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </>
                            )}
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Box>
    )
}
