import * as React from 'react'
import {
    Datagrid,
    DateField,
    EditButton,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    usePermissions,
    useRecordContext,
    useRedirect,
    WithRecord,
    List,
    downloadCSV,
    TopToolbar,
    ExportButton,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'

import { PostShowActions } from '../CustomElements/PostBottomToolbars'
import PermissionHandler from '../../config/PermissionHandler'
import Tooltip from '@mui/material/Tooltip'
import { CustomTitle } from '../CustomElements/CustomTitle'
import { CustomActionButtonEdit as EditBtn } from '../CustomFields/CustomActionButtonsField'

import CustomDataGrid from '../CustomElements/CustomDataGrid'
import { Button, Link } from '@mui/material'
import EmptyList from '../EmptyList'

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    const redirect = useRedirect()

    const IsEditable = (props) => {
        const record = useRecordContext()
        return record?.category !== 'seni-quarter' && record?.category !== 'seni-edu' && <EditButton {...props} variant="outlined" color="info" />
    }

    const exporter = async (payer_discounts, fetchRelatedRecords) => {
        // will call dataProvider.getMany('posts', { ids: records.map(record => record.post_id) }),
        // ignoring duplicate and empty post_id
        //const _payer_discounts = await fetchRelatedRecords(payer_discounts, "id", "payer_discounts");
        const _payer_discounts_formatted = payer_discounts.map((payer_discount) => ({
            Marka: payer_discount.category,
            'Wartość [%]': payer_discount.value,
        }))
        return jsonExport(
            _payer_discounts_formatted,
            {
                headers: ['Marka', 'Wartość [%]'],
            },
            (err, csv) => {
                downloadCSV(csv, 'warunki_handlowe')
            }
        )
    }

    return (
        <Show {...props} actions={<PostShowActions />} title={<CustomTitle resourceName="Płatnik sieciowy" fields={['name']} />}>
            <SimpleShowLayout>
                <TextField variant="outlined" source="name" label="Nazwa sieci" />
                <TextField variant="outlined" source="partnerName" label="Partner" />
                {PermissionHandler(permissions, 'PayerNetworks', 'UpdatePriceList') && (
                    <ReferenceField label="Cennik" source="priceList[@id]" reference="price_lists" link={false}>
                        <TextField variant="outlined" source="name" />
                    </ReferenceField>
                )}
                <ReferenceField label="Regulamin" source="regulation[@id]" reference="regulations" link={false} emptyText="-">
                    <TextField source="name" />
                </ReferenceField>
                <EditBtn />
                <WithRecord
                    render={(record) => (
                        <>
                            <Button
                                color="primary"
                                component={Link}
                                onClick={() => {
                                    redirect(
                                        `/pickup_points?displayedFilters={}&filter={"payer"%3A{"payerNetwork"%3A"%2Fpayer_networks%2F${
                                            record.id.split('/')[2]
                                        }"}}&order=ASC&page=1&perPage=10&sort=id`
                                    )
                                }}
                            >
                                Punkty odbioru
                            </Button>
                            <Button
                                color="primary"
                                component={Link}
                                onClick={() => {
                                    console.log(record)
                                    redirect(
                                        `/payers?displayedFilters={}&filter={"payerNetwork"%3A"%2Fpayer_networks%2F${
                                            record.id.split('/')[2]
                                        }"}&order=ASC&page=1&perPage=10&sort=id`
                                    )
                                }}
                            >
                                Płatnicy
                            </Button>
                        </>
                    )}
                />
                {PermissionHandler(permissions, 'PayerDiscounts', 'Update') && (
                    <WithRecord
                        label="Rabaty"
                        render={(record) => (
                            <List
                                title={" "}
                                resource="payer_discounts"
                                filter={{ payerNetwork: record?.id?.split('/')?.[2] }}
                                empty={<EmptyList title="rabatów" add={false} />}
                                actions={
                                    <TopToolbar>
                                        <ExportButton />
                                    </TopToolbar>
                                }
                                exporter={exporter}
                            >
                                <CustomDataGrid>
                                    <TextField source="category" label="Marka" />
                                    <TextField source="value" label="Wartość [%]" />
                                    <DateField source="updatedAt" label="Aktualizowany" emptyText="brak danych" />
                                    <IsEditable />
                                </CustomDataGrid>
                            </List>
                        )}
                    />
                )}
            </SimpleShowLayout>
        </Show>
    )
}
