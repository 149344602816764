import React from 'react'
import { useState } from 'react'
import { List, useGetList, SelectInput } from 'react-admin'
import CustomActionBar from '../../CustomElements/CustomActionBar'
import EmptyList from '../../EmptyList'
import { CustomPagination } from '../../CustomElements/CustomPagination'
import CustomCardGrid from '../CustomElements/CustomCardGrid'

export default (props) => {
    return (
        <List
            {...props}
            title="Lista nagród"
            pagination={<CustomPagination />}
            actions={<CustomActionBar sortBtnFields={["name","price"]}/>}
            filters={[
                <SelectInput
                    label="Typ nagrody"
                    source="type"
                    variant="outlined"
                    resettable
                    fullWidth
                    choices={[
                        { id: 0, name: 'Rzeczowa' },
                        { id: 1, name: 'Edukacyjna' },
                    ]}
                    alwaysOn
                />,
            ]}
            empty={<EmptyList title="nagród" add={false} />}
        >
            <CustomCardGrid />
        </List>
    )
}
